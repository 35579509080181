import React, { Component } from 'react'
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Form from "./form"

class CarList extends Component {
  static propTypes = {
    items: PropTypes.array,
    formData: PropTypes.object.isRequired,
  }

  static defaultProps = {
    items: [],
  }

  state = {
    isOpen: false,
    selectedItem: -1,
    item: {},
  }

  openModal = (item) => {
    this.setState({ isOpen: true, item });
  }

  renderHeader = (item, index) => {
    return (
      <div
        key={item.id}
        className={(index === this.state.selectedItem ? 'col-4 col-md-3 car-list__header_item active' : 'col-4 col-md-3 car-list__header_item')}
        //onClick={() => { this.setState({ selectedItem: index }) }}
      >
        <a href={`#car-${item.id}`}>
          <Img fluid={item.localPreviewPicture.childImageSharp.fluid} />
          <h3>{item.name}</h3>
        </a>
      </div>
    )
  }

  formatPrice = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  renderBody = (item, index) => {
    const imgAnimated = <div
      key={`${item.id}-img`}
      className="col-12 col-md-6"
      // data-sal={(index % 2) ? "slide-left" : "slide-right"}
      // data-sal-delay="100"
      // data-sal-easing="ease"
    >
      <Img fluid={item.localDetailPicture.childImageSharp.fluid} />
    </div>

    const img = <div
      key={`${item.id}-img`}
      className="col-12 col-md-6"
    >
      <Img fluid={item.localDetailPicture.childImageSharp.fluid} />
    </div>

    const desc = <div key={`${item.id}-desc`} className="col-12 col-md-6">
      <h2>{item.name}</h2>
      {
        (item.props.price && item.props.price.value)
          ? <h3>
            от {this.formatPrice(item.props.price.value, 'руб')} руб.
            </h3>
          : null
      }
      {
        (item.props.profit && item.props.profit.value)
          ? <h3>
            преимущество до <span>{this.formatPrice(item.props.profit.value, 'руб')} руб.</span>
          </h3>
          : null
      }
      <div dangerouslySetInnerHTML={{ __html: item.previewText }} />
      <Form
        formName={this.props.formData.nodes[0].name}
        formItems={this.props.formData.nodes[0].items}
        template={this.props.formData.nodes[0].template}
        formSubmitUrl="https://api.osome.dev/v1/form?id=78"
        setFormValues={{ "CAR": item.name }}
        formShowLabel={false}
        modal={true}
        modalButtonText="Получить предложение"
        hideTitle={true}
        submitText="Получить предложение"
      />
    </div>

    return (
      <>
      <a name={`car-${item.id}`}></a>
      <div
        key={item.id}
        className={(index % 2) ? "row flex-md-row-reverse" : "row"}
        id="car-list"
      >
        {(this.state.selectedItem >= 0) ? [img, desc] : [imgAnimated, desc]}
      </div>
      </>
    )
  }

  render() {
    return (
      <>
        <h2 className="title"><span>Модельный ряд</span></h2>
        <div className="row car-list__header">
          {this.props.items.map(this.renderHeader)}
        </div>
        <div className="car-list__body">
          {
            //(this.state.selectedItem >= 0)
              //? this.renderBody(this.props.items[this.state.selectedItem])
              this.props.items.map(this.renderBody)
          }
        </div>
      </>
    );
  }
}



export default CarList